import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
import MeetTheTeamGrid from '../components/meet-the-team-grid/meet-the-team-grid.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='Meet The Team' description='Meet the team at Fresh Pets.' keywords={['Fresh', 'Pets', 'Team']} mdxType="SEO" />
    <h1>{`Meet The Team`}</h1>
    <MeetTheTeamGrid descriptions={true} mdxType="MeetTheTeamGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      